* {
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
    background-color: darkgray;
}

.app-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.game-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.board-info {
    background: rgb(100, 100, 150);
    color: white;
    margin-right: 20px;
    padding: 0.5rem;
    border-radius: 10px;
    display: grid;
    grid-template-rows: repeat(5, auto);
    grid-template-columns: repeat(2, auto);
}

.board-info-key {
    min-width: 100px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.board-info-value {
    min-width: 100px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.moves-history {
    width: 200px;
    height: 550px;
    margin-left: 20px;
    padding: 0.5rem;
    border-radius: 10px;
    background: rgb(100, 100, 150);
    color: white;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
}

.moves-history span {
    height: 1.25em;
    margin: 0.25em;
    font-weight: bold;
}

.moves-history .move-number {
    color: orange;
}

.moves-history .move-name {
    color: white;
}

.moves-history .move-name-current {
    color: lightgreen;
}

.moves-history .move-name:hover {
    cursor: pointer;
}

.board {
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-rows: 15px repeat(8, 65px) 15px;
    grid-template-columns: 15px repeat(8, 65px) 15px;
}

.board-square {
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.white-square {
    background-color: white;
}

.black-square {
    background-color: rgb(100, 140, 190);
}

.selected-square {
    background-color: green;
}

.board-edge {
    background-color: darkblue;
    border: none;
    padding: 0;
    margin: 0;
}

.board .piece-name {
    background-size: cover;
}

.board .piece-name-bB {
    background-image: url('./images/Chess_bdt45.svg');
}
.board .piece-name-wB {
    background-image: url('./images/Chess_blt45.svg');
}

.board .piece-name-bK {
    background-image: url('./images/Chess_kdt45.svg');
}
.board .piece-name-wK {
    background-image: url('./images/Chess_klt45.svg');
}

.board .piece-name-bN {
    background-image: url('./images/Chess_ndt45.svg');
}
.board .piece-name-wN {
    background-image: url('./images/Chess_nlt45.svg');
}

.board .piece-name-bP {
    background-image: url('./images/Chess_pdt45.svg');
}
.board .piece-name-wP {
    background-image: url('./images/Chess_plt45.svg');
}

.board .piece-name-bQ {
    background-image: url('./images/Chess_qdt45.svg');
}
.board .piece-name-wQ {
    background-image: url('./images/Chess_qlt45.svg');
}

.board .piece-name-bR {
    background-image: url('./images/Chess_rdt45.svg');
}
.board .piece-name-wR {
    background-image: url('./images/Chess_rlt45.svg');
}
